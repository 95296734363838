import { default as classnames, default as classNames } from "classnames";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { Alert, FormGroup, Input, InputGroup } from "reactstrap";

import Widget from "../../../../components/Widget";
import { COMPANY, EMAIL, NAME, UTM_CAMPAIGN, UTM_SOURCE } from "../../../../utils/app-constants.json";
import { purchasePlanTermsLink, purchasePrivacyPolicyLink } from "../../../../utils/app-links";
import { isArrayWithLength } from "../../../../utils/array-methods";
import { getSearchParams, isAuthenticated } from "../../../../utils/common";
import { isEmptyString } from "../../../../utils/format-string";
import { receiveToken } from "../../actions/auth";
import { signUp } from "../../actions/signup";
import s from "./Signup.module.scss";
import { passwordItems, SIGNUP_PAGE, validatePassword, validateSignUpForm } from "./utils";

const SignupForm = ({ location }) => {
  const dispatch = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { t } = useTranslation(["signupForm"]);
  const paramsEmail = getSearchParams(location, EMAIL);
  const paramsFullName = getSearchParams(location, NAME);
  const paramsCompany = getSearchParams(location, COMPANY);

  const [email, setEmail] = useState("");

  const { signedUp, isFetching, errorMessage } = useSelector(({ signup }) => {
    return {
      signedUp: signup.signedUp,
      isFetching: signup.isFetching,
      errorMessage: signup.errorMessage,
    };
  }, shallowEqual);

  const handleSignUp = async ({
    name,
    company,
    password,
    email,
    customerDesignation,
  }) => {
    const captchaToken = await executeRecaptcha(SIGNUP_PAGE);
    if (!isEmptyString(captchaToken)) {
      const campaignKey = getSearchParams(location, UTM_CAMPAIGN);
      const sourceKey = getSearchParams(location, UTM_SOURCE);
      setEmail(email);
      dispatch(
        signUp(
          {
            Name: name,
            CompanyName: company,
            Email: email,
            Password: password,
            CaptchaCode: captchaToken,
            Designation: customerDesignation,
          },
          campaignKey,
          sourceKey
        )
      );
    }
  };

  const clearSignUpData = () => {
    dispatch({ type: "SIGNUP_PAGE_UNLOADED" });
    dispatch({ type: "AUTH_PAGE_UNLOADED" });
  };

  useEffect(() => {
    const token = getSearchParams(location, "token");
    if (token) {
      dispatch(receiveToken(token));
    }
    return () => {
      clearSignUpData();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isAuthenticated()) {
    const { from } = location.state || {
      from: { pathname: "/app" },
    };
    return <Navigate to={from} />;
  }

  if (signedUp) {
    const emailEncoded = encodeURIComponent(email);
    const redirectUrl = `/verify?email=${emailEncoded}`;
    return <Navigate to={redirectUrl} />;
  }

  const emptyRow = <span>&nbsp;</span>;

  return (
    <Widget
      className={classnames("widget mx-auto", s.SignUpForm)}
      title={<div className={s.WidgetTitle}>{t("pageTitle")}</div>}
    >
      <div>
        <Formik
          initialValues={{
            name: paramsFullName || "",
            company: paramsCompany || "",
            password: "",
            email: paramsEmail || "",
            customerDesignation: "",
          }}
          validateOnBlur={true}
          validate={(values) => {
            return validateSignUpForm(values);
          }}
          onSubmit={(values) => {
            handleSignUp(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldTouched,
          }) => (
            <form onSubmit={handleSubmit}>
              {errorMessage && (
                <Alert className="alert-sm" color="danger">
                  {errorMessage}
                </Alert>
              )}

              <FormGroup className="mt-3 mb-0">
                <div>
                  <InputGroup
                    className={classnames(
                      errors.name && touched.name && s.InputError
                    )}
                  >
                    <Input
                      className={classnames("form-control")}
                      value={values.name}
                      onChange={handleChange}
                      type="text"
                      name="name"
                      placeholder={t("fullName")}
                      onBlur={handleBlur}
                    />
                    <span className="input-group-text">
                      <i className="fi flaticon-id-card-4" />
                    </span>
                  </InputGroup>
                  <div className={s.FormError}>
                    {errors.name ? touched.name && errors.name : emptyRow}
                  </div>
                </div>
              </FormGroup>

              <FormGroup className="mt-2 mb-0">
                <div>
                  <InputGroup
                    className={classnames(
                      errors.email && touched.email && s.InputError
                    )}
                  >
                    <Input
                      className="form-control"
                      value={values.email}
                      onChange={handleChange}
                      type="text"
                      name="email"
                      placeholder={t("email")}
                      disabled={paramsEmail}
                      onBlur={handleBlur}
                    />
                    <span className="input-group-text">
                      <i className="fa fa-envelope-o" />
                    </span>
                  </InputGroup>
                  <div className={s.FormError}>
                    {errors.email ? touched.email && errors.email : emptyRow}
                  </div>
                </div>
              </FormGroup>

              <FormGroup className="mt-2 mb-0">
                <div>
                  <InputGroup
                    className={classnames(
                      errors.company && touched.company && s.InputError
                    )}
                  >
                    <Input
                      className="form-control"
                      value={values.company}
                      onChange={handleChange}
                      type="text"
                      name="company"
                      placeholder={t("company")}
                      onBlur={handleBlur}
                    />
                    <span className="input-group-text">
                      <i className="fa fa-building-o" />
                    </span>
                  </InputGroup>
                  <div className={s.FormError}>
                    {errors.company
                      ? touched.company && errors.company
                      : emptyRow}
                  </div>
                </div>
              </FormGroup>

              <FormGroup className="mt-2 mb-0">
                <div>
                  <InputGroup
                    className={classnames(
                      errors.customerDesignation &&
                        touched.customerDesignation &&
                        s.InputError
                    )}
                  >
                    <Input
                      className="form-control"
                      value={values.customerDesignation}
                      onChange={handleChange}
                      type="text"
                      name="customerDesignation"
                      placeholder={t("jobTitle")}
                      onBlur={handleBlur}
                    />
                    <span className="input-group-text">
                      <i className="fi flaticon-user-3" />
                    </span>
                  </InputGroup>
                  <div className={s.FormError}>
                    {errors.customerDesignation
                      ? touched.customerDesignation &&
                        errors.customerDesignation
                      : emptyRow}
                  </div>
                </div>
              </FormGroup>

              <FormGroup className="mt-2 mb-0">
                <div>
                  <InputGroup
                    className={classnames(
                      errors.password && touched.password && s.InputError
                    )}
                  >
                    <Input
                      className="form-control"
                      value={values.password}
                      onChange={(e) => {
                        setFieldTouched("password", e.target.value);
                        handleChange(e);
                      }}
                      type="password"
                      name="password"
                      placeholder={t("createPassword")}
                      onBlur={handleBlur}
                      autoComplete="new-password"
                    />
                    <span className="input-group-text">
                      <i className="fi flaticon-locked-4" />
                    </span>
                  </InputGroup>
                  {isArrayWithLength(passwordItems) && (
                    <div className={s.PasswordValidation}>
                      <span
                        className={classNames(
                          errors.password &&
                            touched.password &&
                            s.inValidPassword,
                          "mc-text-sm-regular mt-2"
                        )}
                      >
                        {t("common:mustContain")}
                      </span>
                      <ul>
                        {passwordItems.map((item) => {
                          const isValid = validatePassword(
                            values.password,
                            item.key
                          );
                          return (
                            <li key={item.key} className="mc-text-sm-regular">
                              {errors.password &&
                              touched.password &&
                              !isValid ? (
                                <i
                                  className={classnames(
                                    "fa fa-times",
                                    s.ErrorIcon
                                  )}
                                ></i>
                              ) : (
                                <i
                                  className={classnames(
                                    "fa fa-check",
                                    isValid && s.isValid
                                  )}
                                ></i>
                              )}
                              &nbsp;
                              {item.value}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )}
                </div>
              </FormGroup>
              <div className="btn-toolbar mt-4 mb-2">
                <button
                  type="submit"
                  href="/app"
                  disabled={isFetching}
                  className={classnames(
                    "btn btn-primary btn-md ml-0",
                    s.width100
                  )}
                >
                  {isFetching
                    ? `${t("creatingAccount")}...`
                    : t("createAccount")}
                </button>
              </div>
              <FormGroup className="mt-4 mb-0">
                <div className={"mc-text-sm-regular"}>
                  {t("userAgreementMsg")}
                  <a
                    className={`${s.TermsBtn} mc-text-sm-regular`}
                    href={purchasePlanTermsLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("btn.T&C")}
                  </a>
                  {t("and")}
                  <a
                    className={`${s.TermsBtn} mc-text-sm-regular`}
                    href={purchasePrivacyPolicyLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("btn.privacyPolicy")}
                  </a>
                </div>
              </FormGroup>
            </form>
          )}
        </Formik>
        <h6 className="clearfix mt-4">
          {t("haveAccount")}{" "}
          <Link className="mt-sm mt-2" to="/login">
            {t("loginLink")}
          </Link>
        </h6>
      </div>
    </Widget>
  );
};

export default SignupForm;
