import React, { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "reactstrap";
import s from "./CopyScript.module.scss";
import classnames from "classnames";
import MCButton from "../MCButton/MCButton";
import PropTypes from "prop-types";

const CopyScript = ({ text, id, textClassName }) => {
  const { t } = useTranslation(["common"]);
  const [textCopied, setTextCopied] = useState(false);

  const handleCopyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(text);
    setTextCopied(true);
    setTimeout(() => {
      setTextCopied(false);
    }, 2000);
  }, [text]);

  const target = `copy-text-${id}`;
  return (
    <div
      className={classnames(
        s.CopyScriptContainer,
        "d-flex flex-column justify-content-between h-100"
      )}
    >
      <div className={s.CopyScriptArea}>
        <div className={classnames(textClassName, "break-word", "break-all")}>
          {text}
        </div>
        <Tooltip isOpen={textCopied} placement="top" target={target}>
          <span>
            <i className={"fa fa-check"} /> {t("common:copied")}!
          </span>
        </Tooltip>
      </div>
      <div
        className={classnames("align-self-center", "mt-4", "mb-2", "text-bold")}
      >
        <MCButton
          type="button"
          text={t("copyToClipboard")}
          id={target}
          icon="fa fa-clone"
          color="mc-blue"
          onClick={() => handleCopyToClipboard()}
        />
      </div>
    </div>
  );
};

CopyScript.propTypes = {
  text: PropTypes.string,
  id: PropTypes.string,
  textClassName: PropTypes.string,
};

CopyScript.defaultProps = {
  text: "",
  id: "",
  textClassName: "",
};

export default CopyScript;
