import {
  RESOURCES_LIST_URL
} from "../../../config/service/index";
import { getApiService } from "../../../helpers/apiService";
import { WEB_REQUEST_TYPE } from "../../../helpers/apiServiceHelper";

export const getAllAccountsApi = params => {
  return getApiService(WEB_REQUEST_TYPE.GET, RESOURCES_LIST_URL + "?" + params)
    .then(response => {
      const { data } = response;
      return data;
    })
    .catch(error => {
      throw Error(error);
    });
};
