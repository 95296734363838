import { GET_ASSESSMENT_QUESTION_REASONS_SUCCESS } from "../actionTypes/assessments";
import {
  CREATE_MILESTONE_FAILURE,
  CREATE_MILESTONE_REQUEST,
  CREATE_MILESTONE_SUCCESS,
  CREATE_MILESTONE_RESET,
  LIST_MILESTONE_REQUEST,
  LIST_MILESTONE_SUCCESS,
  LIST_MILESTONE_PICKER_SUCCESS,
  LIST_MILESTONE_FAILURE,
  GET_MILESTONE_DETAILS_REQUEST,
  GET_MILESTONE_DETAILS_FAILURE,
  GET_MILESTONE_DETAILS_SUCCESS,
  GET_MILESTONE_DETAILS_RESET,
  GET_ASSESSMENT_MILESTONES_QUESTIONS_REQUEST,
  GET_ASSESSMENT_MILESTONES_QUESTIONS_SUCCESS,
  GET_ASSESSMENT_MILESTONES_QUESTIONS_FAILURE,
  GET_ASSESSMENT_MILESTONE_QUESTION_DETAILS_SUCCESS,
  GET_ASSESSMENT_MILESTONE_QUESTION_DETAILS_REQUEST,
  GET_ASSESSMENT_MILESTONE_QUESTION_DETAILS_FAILURE,
  LIST_MILESTONE_RESET,
  CLEAR_MILESTONE_QUESTION_DETAILS,
} from "../actionTypes/milestones";

const initialState = {
  creatingMilestone: false,
  createdMilestone: false,
  isFetchingMilestoneList: false,
  milestoneList: [],
  milestoneDetails: null,
  isFetchingMilestoneDetails: false,
  fetchingMilestoneDetailsError: null,
  fetchingMilestoneChecksSummary: false,
  fetchingMilestoneChecksSummaryError: false,
  assessmentMilestonePillarChecksSummary: null,
  assessmentMilestoneQuestionChecksSummary: null,
  fetchingAssessmentQuestions: false,
  assessmentQuestions: [],
  fetchingAssessmentQuestionsError: false,
  fetchingAssessmentQuestionDetails: false,
  fetchingAssessmentQuestionDetailsError: false,
  assessmentQuestionDetails: null,
  assessmentQuestionReasons: [],
};

const assessmentMilestonesReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_MILESTONE_SUCCESS: {
      return {
        ...state,
        creatingMilestone: false,
        createdMilestone: true,
      };
    }
    case CREATE_MILESTONE_FAILURE: {
      return {
        ...state,
        creatingMilestone: false,
      };
    }
    case CREATE_MILESTONE_REQUEST: {
      return {
        ...state,
        creatingMilestone: true,
      };
    }
    case CREATE_MILESTONE_RESET: {
      return {
        ...state,
        createdMilestone: false,
        creatingMilestone: false,
      };
    }
    case LIST_MILESTONE_REQUEST: {
      return {
        ...state,
        isFetchingMilestoneList: true,
      };
    }
    case LIST_MILESTONE_SUCCESS: {
      return {
        ...state,
        isFetchingMilestoneList: false,
        milestoneList: action.payload,
      };
    }
    case LIST_MILESTONE_PICKER_SUCCESS: {
      const MilestonesList = action.payload.MilestonesList;
      const uniqueMilestones = MilestonesList.filter(
        (milestone) =>
          !state.milestoneList.some(
            (data) => data.MilestoneId === milestone.MilestoneId
          )
      );
      return {
        ...state,
        isFetchingMilestoneList: false,
        milestoneList: [...state.milestoneList, ...uniqueMilestones],
        totalMilestones: action.payload.TotalRecords,
      };
    }
    case LIST_MILESTONE_FAILURE: {
      return {
        ...state,
        isFetchingMilestoneList: false,
      };
    }
    case GET_MILESTONE_DETAILS_REQUEST: {
      return {
        ...state,
        isFetchingMilestoneDetails: true,
      };
    }
    case GET_MILESTONE_DETAILS_SUCCESS: {
      return {
        ...state,
        isFetchingMilestoneDetails: false,
        milestoneDetails: action.payload,
      };
    }
    case GET_MILESTONE_DETAILS_FAILURE: {
      return {
        ...state,
        isFetchingMilestoneDetails: false,
        fetchingMilestoneDetailsError: true,
      };
    }
    case GET_MILESTONE_DETAILS_RESET: {
      return {
        ...state,
        milestoneDetails: initialState.milestoneDetails,
        isFetchingMilestoneDetails: initialState.isFetchingMilestoneDetails,
      };
    }
    case GET_ASSESSMENT_MILESTONES_QUESTIONS_REQUEST: {
      return {
        ...state,
        fetchingAssessmentQuestions: true,
        fetchingAssessmentQuestionsError: false,
      };
    }
    case GET_ASSESSMENT_MILESTONES_QUESTIONS_SUCCESS: {
      return {
        ...state,
        fetchingAssessmentQuestions: false,
        assessmentQuestions: action.payload,
      };
    }
    case GET_ASSESSMENT_MILESTONES_QUESTIONS_FAILURE: {
      return {
        ...state,
        fetchingAssessmentQuestions: false,
        fetchingAssessmentQuestionsError: true,
      };
    }
    case GET_ASSESSMENT_MILESTONE_QUESTION_DETAILS_REQUEST: {
      return {
        ...state,
        fetchingAssessmentQuestionDetails: true,
        fetchingAssessmentQuestionDetailsError: false,
      };
    }
    case GET_ASSESSMENT_MILESTONE_QUESTION_DETAILS_SUCCESS: {
      return {
        ...state,
        fetchingAssessmentQuestionDetails: false,
        assessmentQuestionDetails: action.payload,
      };
    }
    case GET_ASSESSMENT_MILESTONE_QUESTION_DETAILS_FAILURE: {
      return {
        ...state,
        fetchingAssessmentQuestionDetails: false,
        fetchingAssessmentQuestionDetailsError: true,
      };
    }
    case GET_ASSESSMENT_QUESTION_REASONS_SUCCESS: {
      return {
        ...state,
        assessmentQuestionReasons: action.payload,
      };
    }
    case LIST_MILESTONE_RESET: {
      return {
        milestoneList: initialState.milestoneList,
      };
    }
    case CLEAR_MILESTONE_QUESTION_DETAILS: {
      return {
        ...state,
        fetchingAssessmentQuestions: initialState.fetchingAssessmentQuestions,
        assessmentQuestions: initialState.assessmentQuestions,
        fetchingAssessmentQuestionsError:
          initialState.fetchingAssessmentQuestionsError,
        fetchingAssessmentQuestionDetails:
          initialState.fetchingAssessmentQuestionDetails,
        fetchingAssessmentQuestionDetailsError:
          initialState.fetchingAssessmentQuestionDetailsError,
        assessmentQuestionDetails: initialState.assessmentQuestionDetails,
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default assessmentMilestonesReducer;
