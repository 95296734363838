//Get Management Accounts
export const GET_FBP_MANAGEMENT_ACCOUNTS_REQUEST =
  "GET_FBP_MANAGEMENT_ACCOUNTS_REQUEST";
export const GET_FBP_MANAGEMENT_ACCOUNTS_SUCCESS =
  "GET_FBP_MANAGEMENT_ACCOUNTS_SUCCESS";
export const GET_FBP_MANAGEMENT_ACCOUNTS_FAILURE =
  "GET_FBP_MANAGEMENT_ACCOUNTS_FAILURE";

export const SET_FBP_PARAMETERS = "SET_FBP_PARAMETERS";
export const CLEAR_FBP_PARAMETERS = "CLEAR_FBP_PARAMATERS";

export const SET_FBP_NOTIFICATION_PARAMETER = "SET_FBP_NOTIFICATION_PARAMETERS";
export const CLEAR_FBP_NOTIFICATION_PARAMETER =
  "CLEAR_FBP_NOTIFICATION_PARAMETER";
