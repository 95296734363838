import {
  ADD_RESOURCES_TO_DEPT_FAILED,
  ADD_RESOURCES_TO_DEPT_REQUEST,
  ADD_RESOURCES_TO_DEPT_SUCCESS,
  CLEAR_DEPARTMENT_DATA,
  CLEAR_DISCOVERY_SUMMARY,
  CLEAR_FIRST_RUN_DATA,
  CLEAR_SELECTED_DEPT,
  CONFIGURE_REGIONS_FAILED,
  CONFIGURE_REGIONS_REQUEST,
  CONFIGURE_REGIONS_SUCCESS,
  EDIT_ORGANIZATION_FAILED,
  EDIT_ORGANIZATION_REQUEST,
  EDIT_ORGANIZATION_SUCCESS,
  GET_ALL_DEPARTMENTS_FAILED,
  GET_ALL_DEPARTMENTS_REQUEST,
  GET_ALL_DEPARTMENTS_SUCCESS,
  GET_ALL_ORGANIZATIONS_FAILED,
  GET_ALL_ORGANIZATIONS_REQUEST,
  GET_ALL_ORGANIZATIONS_SUCCESS,
  GET_ALL_RESOURCES_FAILED,
  GET_ALL_RESOURCES_REQUEST,
  GET_ALL_RESOURCES_SUCCESS,
  GET_DISCOVERY_SUMMARY_FAILED,
  GET_DISCOVERY_SUMMARY_REQUEST,
  GET_DISCOVERY_SUMMARY_SUCCESS,
  OFFBOARD_MEMBER_ACCOUNT_FAILED,
  OFFBOARD_MEMBER_ACCOUNT_REQUEST,
  OFFBOARD_MEMBER_ACCOUNT_SUCCESS,
  ONBOARD_MEMBER_ACCOUNT_FAILED,
  ONBOARD_MEMBER_ACCOUNT_REQUEST,
  ONBOARD_MEMBER_ACCOUNT_SUCCESS,
  ORG_HIERARCHY_ERROR,
  ORG_HIERARCHY_REQUEST,
  ORG_HIERARCHY_SUCCESS,
  SAVE_DEPARTMENT_FAILED,
  SAVE_DEPARTMENT_REQUEST,
  SAVE_DEPARTMENT_SUCCESS,
  SAVE_ORGANIZATION_FAILED,
  SAVE_ORGANIZATION_REQUEST,
  SAVE_ORGANIZATION_SUCCESS,
  SET_ACCOUNT_TYPE,
  SET_DEPARTMENT_DESCRIPTION,
  SET_DEPARTMENT_NAME,
  SET_HIDE_CLOSE_BUTTON,
  SET_ORGANIZATION_DESCRIPTION,
  SET_ORGANIZATION_NAME,
  SET_SELECTED_CLOUD_PROVIDER,
  SET_SELECTED_DEPT,
  UPDATE_DEPARTMENT_SEARCH_KEY,
  TOGGLE_VIEW_TYPE,
} from "../actionTypes/firstRun";

import {
  GLOBAL_CONFIG_DEPARTMENT,
  GLOBAL_CONFIG_ORGANIZATION,
} from "../../../reducers/global-config";

import {
  editOrganizationApi,
  getAllOrganizationsApi,
  saveOrganizationApi,
} from "../../organization/services/organization";

import {
  getAccounts,
  getAzureSubscriptions,
} from "../../account/actions/accounts";
import {
  addResourcesToDeptApi,
  editDepartmentApi,
  getAllDepartmentsApi,
  saveDepartmentApi,
} from "../../organization/services/department";

import { getAllResourcesApi } from "../../organization/services/resource";

import {
  getDiscoverySummaryApi,
  getOrgHierarchyApi,
  offboardMemberAccountApi,
  onboardMemberAccountApi,
} from "../services/firstRun";

import { configureRegionsApi } from "../../account/services/account";
import i18n from "../../../config/locale/i18n";
import { getRegionName } from "../../../utils/account-region";
import { CLOUDPROVIDERS } from "../../../utils/app-constants.json";
import { isArrayWithLength } from "../../../utils/array-methods";
import { AZURE_RESOURCE_IMAGES_KEYS } from "../../../utils/azure-resource-images";
import azureResourceList from "../../../utils/azure-resourceList.json";
import { showErrorNotification } from "../../../utils/error-notification";
import { postNotification } from "../../../utils/notification-message";
import { getMatchKey } from "../../../utils/object-methods";
import regionCodes from "../../../utils/regionFlagCodes.json";
import awsResourceList from "../../../utils/resourceList.json";
import {
  DEPARTMENT_ADDED_MESSAGE,
  DEPARTMENT_EDIT_MESSAGE,
  ORGANIZATION_EDIT_MESSAGE,
} from "../constants/firstRun";

export const setOrganizationName = (orgName) => ({
  type: SET_ORGANIZATION_NAME,
  payload: orgName,
});

export const setOrganizationDescription = (orgDescription) => ({
  type: SET_ORGANIZATION_DESCRIPTION,
  payload: orgDescription,
});

export const setDepartmentName = (deptName) => ({
  type: SET_DEPARTMENT_NAME,
  payload: deptName,
});

export const setDepartmentDescription = (deptDescription) => ({
  type: SET_DEPARTMENT_DESCRIPTION,
  payload: deptDescription,
});

const requestSaveOrganization = () => {
  return {
    type: SAVE_ORGANIZATION_REQUEST,
  };
};

const receiveSaveOrganization = (payload) => {
  return {
    type: SAVE_ORGANIZATION_SUCCESS,
    payload,
  };
};

const saveOrganizationError = (payload) => {
  return {
    type: SAVE_ORGANIZATION_FAILED,
    payload,
  };
};

export const saveOrganization = (param, successCallback = () => {}) => {
  return (dispatch) => {
    dispatch(requestSaveOrganization());
    saveOrganizationApi(param)
      .then((data) => {
        dispatch({ type: "SET_SAVING", saving: true });
        const saveOrg = () => {
          return dispatch(receiveSaveOrganization(data));
        };

        const savingOrgPromsie = Promise.resolve(saveOrg());

        savingOrgPromsie.then(() => {
          successCallback();
        });
      })
      .catch((err) => {
        dispatch(saveOrganizationError(err.body));
        showErrorNotification(dispatch, err);
      });
  };
};

const requestSaveDepartment = () => {
  return {
    type: SAVE_DEPARTMENT_REQUEST,
  };
};

const receiveSaveDepartment = (payload) => (dispatch) => {
  dispatch({
    type: SAVE_DEPARTMENT_SUCCESS,
    payload: payload,
  });
  dispatch(
    setSelectedDepartment({
      ID: payload.ID,
    })
  );
};

const saveDepartmentError = (payload) => {
  return {
    type: SAVE_DEPARTMENT_FAILED,
    payload,
  };
};
export const saveDepartment = (param) => (dispatch, getState) => {
  const { globalConfig } = getState();
  const { organizations } = globalConfig;
  if (organizations && organizations.length) {
    dispatch(requestSaveDepartment());
    const payload = {
      Name: param.Name,
      Description: param.Description,
    };
    if (param.hasOwnProperty("Accounts")) {
      payload.Accounts = param.Accounts;
    }
    saveDepartmentApi(payload, organizations[0].ID)
      .then((data) => {
        dispatch(receiveSaveDepartment(data));
        dispatch(getAllDepartments());
        if (!param.FRE) {
          dispatch(getAllOrganizations());
          dispatch(postNotification(DEPARTMENT_ADDED_MESSAGE, "success"));
        }
      })
      .catch((err) => {
        const error = err.message && JSON.parse(err.message);
        const message = error && error.data && error.data.ErrorMessage;
        dispatch(saveDepartmentError(message));
        dispatch(postNotification(message, "danger"));
      });
  }
};

const requestEditOrganization = () => ({
  type: EDIT_ORGANIZATION_REQUEST,
});

const receiveEditOrganization = (payload) => ({
  type: EDIT_ORGANIZATION_SUCCESS,
  payload: payload,
});

const editOrganizationError = (payload) => ({
  type: EDIT_ORGANIZATION_FAILED,
  payload,
});

export const editOrganization = (orgId, param) => (dispatch, getState) => {
  const { globalConfig } = getState();
  const { organizations } = globalConfig;
  if (organizations && organizations.length) {
    dispatch(requestEditOrganization());
    editOrganizationApi(param, organizations[0].ID)
      .then((data) => {
        dispatch(receiveEditOrganization(data));
        dispatch(postNotification(ORGANIZATION_EDIT_MESSAGE, "success"));
      })
      .catch((err) => {
        const error = JSON.parse(err.message);
        const message = error && error.data && error.data.ErrorMessage;
        dispatch(editOrganizationError(message));
        dispatch(postNotification(message, "danger"));
      });
  }
};

export const editDepartment = (deptId, param) => (dispatch, getState) => {
  const { globalConfig } = getState();
  const { organizations } = globalConfig;
  if (organizations && organizations.length) {
    dispatch(requestSaveDepartment());
    editDepartmentApi(param, organizations[0].ID, deptId)
      .then((data) => {
        dispatch(receiveSaveDepartment(data));
        dispatch(getAllDepartments());
        dispatch(postNotification(DEPARTMENT_EDIT_MESSAGE, "success"));
      })
      .catch((err) => {
        const error = JSON.parse(err.message);
        const message = error && error.data && error.data.ErrorMessage;
        dispatch(saveDepartmentError(message));
        dispatch(postNotification(message, "danger"));
      });
  }
};

const requestAllOrganizations = () => {
  return {
    type: GET_ALL_ORGANIZATIONS_REQUEST,
  };
};

const receiveAllOrganizations = (payload) => {
  return {
    type: GET_ALL_ORGANIZATIONS_SUCCESS,
    payload,
  };
};

const getAllOrganizationsError = (payload) => {
  return {
    type: GET_ALL_ORGANIZATIONS_FAILED,
    payload,
  };
};

export const getAllOrganizations =
  (fetchAccounts = true) =>
  (dispatch, getState) => {
    dispatch(requestAllOrganizations());
    getAllOrganizationsApi()
      .then((data) => {
        var orgsAppsEnvs = preprocessAppsEnvs(data);
        dispatch({
          type: GLOBAL_CONFIG_ORGANIZATION,
          payload: orgsAppsEnvs,
        });
        dispatch({
          type: GLOBAL_CONFIG_DEPARTMENT,
          payload: orgsAppsEnvs.Departments,
        });
        dispatch(receiveAllOrganizations(data));
        if (fetchAccounts) {
          dispatch(getAzureSubscriptions());
          dispatch(getAccounts());
        }
      })
      .catch((err) => {
        dispatch(getAllOrganizationsError(err.body));
      });
  };

const preprocessAppsEnvs = (orgs) => {
  var depts = [];
  var apps = [];
  var envs = [];
  orgs &&
    orgs.forEach((org) => {
      org.Departments &&
        org.Departments.forEach((dept) => {
          depts.push(dept);
          dept.Applications &&
            dept.Applications.forEach((app) => {
              apps.push(app);
              app.Environments &&
                app.Environments.forEach((env) => {
                  envs.push(env);
                });
            });
        });
    });
  return {
    Organizations: orgs,
    Departments: depts,
    Applications: apps,
    Environments: envs,
  };
};

const requestAllDepartments = () => {
  return {
    type: GET_ALL_DEPARTMENTS_REQUEST,
  };
};

const receiveAllDepartments = (payload) => {
  return {
    type: GET_ALL_DEPARTMENTS_SUCCESS,
    payload: payload,
  };
};

const getAllDepartmentsError = (payload) => {
  return {
    type: GET_ALL_DEPARTMENTS_FAILED,
    payload,
  };
};
export const getAllDepartments = () => (dispatch, getState) => {
  const { globalConfig } = getState();
  const { organizations } = globalConfig;
  if (organizations && organizations.length) {
    dispatch(requestAllDepartments());
    getAllDepartmentsApi(organizations[0].ID)
      .then((data) => {
        dispatch(receiveAllDepartments(data));
        dispatch({
          type: GLOBAL_CONFIG_DEPARTMENT,
          payload: data,
        });
      })
      .catch((err) => {
        dispatch(getAllDepartmentsError(err.body));
      });
  }
};

const requestGetDiscoverySummary = () => {
  return {
    type: GET_DISCOVERY_SUMMARY_REQUEST,
  };
};

const receiveDiscoverySummary = (payload) => {
  return {
    type: GET_DISCOVERY_SUMMARY_SUCCESS,
    payload,
  };
};

export const getDiscoverySummaryError = (payload) => {
  return {
    type: GET_DISCOVERY_SUMMARY_FAILED,
    payload,
  };
};

const requestConfigureRegions = () => {
  return {
    type: CONFIGURE_REGIONS_REQUEST,
  };
};

const configureRegionsSuccess = () => {
  return {
    type: CONFIGURE_REGIONS_SUCCESS,
  };
};

const configureRegionsError = () => {
  return {
    type: CONFIGURE_REGIONS_FAILED,
  };
};

export const configureRegions = (accountId, selectedRegions) => (dispatch) => {
  var payload = {
    AccountNumber: accountId,
    Regions: selectedRegions,
  };

  dispatch(requestConfigureRegions());
  configureRegionsApi(payload)
    .then((data) => {
      dispatch(configureRegionsSuccess());
    })
    .catch((err) => {
      dispatch(configureRegionsError(err.body));
    });
};

export const fetchOrgHierarchy = (customerAccountId) => (dispatch) => {
  if (customerAccountId) {
    dispatch(orgHierarchyRequest());
    getOrgHierarchyApi(customerAccountId)
      .then((data) => {
        dispatch(orgHierarchySuccess(data));
      })
      .catch((err) => {
        dispatch(orgHierarchyError(err.body));
      });
  }
};

export const onboardMemberAccount =
  ({ payload, onSuccess = () => {}, onComplete = () => {} }) =>
  (dispatch) => {
    dispatch(onboardMemberAccountRequest());
    onboardMemberAccountApi(payload)
      .then((data) => {
        onComplete();
        dispatch(onboardMemberAccountSuccess(payload));
        onSuccess();
      })
      .catch((err) => {
        onComplete();
        dispatch(onboardMemberAccountError(err.body));
        dispatch(
          postNotification(i18n.t("common:apiMsg.failedOnboarding"), "danger")
        );
      });
  };

export const offboardMemberAccount =
  ({ customerAccountId, onSuccess = () => {}, onComplete = () => {} }) =>
  (dispatch) => {
    dispatch(offboardMemberAccountRequest());
    offboardMemberAccountApi(customerAccountId)
      .then((data) => {
        onComplete();
        onSuccess();
        dispatch(offboardMemberAccountSuccess(data));
        dispatch(getAccounts());
      })
      .catch((err) => {
        onComplete();
        dispatch(offboardMemberAccountError(err.body));
        dispatch(
          postNotification(i18n.t("common:apiMsg.failedOffboarding"), "danger")
        );
      });
  };

export const getDiscoverySummary =
  ({ accountId, selectedRegions, cloudProvider, customerAccountId }) =>
  (dispatch, getState) => {
    var payload = {
      isDiscoveryApi: true,
    };

    const isAzure = cloudProvider === CLOUDPROVIDERS.AZURE ? true : false;

    if (isArrayWithLength(selectedRegions)) {
      payload.Regions = selectedRegions;
    }

    if (isAzure) {
      payload.CustomerAccountId = [customerAccountId];
    } else {
      payload.Accounts = [accountId];
    }

    const { firstRun, regions } = getState();
    const prevDiscoveryDetails = firstRun.discoveryDetails;
    const test = encodeURI(JSON.stringify(payload));
    const params = `Filters=${test}&CloudProvider=${cloudProvider}`;
    dispatch(requestGetDiscoverySummary());
    getDiscoverySummaryApi(params)
      .then((data) => {
        var preprocessedData = preprocessDiscoveryDetails(
          prevDiscoveryDetails,
          data,
          isAzure ? regions.azureRegionList : regions.regionList,
          isAzure
        );
        dispatch(receiveDiscoverySummary(preprocessedData));
      })
      .catch((err) => {
        dispatch(getDiscoverySummaryError(err.body));
      });
  };

const preprocessDiscoveryDetails = (oldData, newData, regionList, isAzure) => {
  var response = {
    Summary: newData.Summary,
    RegionSummary: [],
    ResourceSummary: [],
  };
  if (newData && isArrayWithLength(newData.RegionSummary)) {
    newData.RegionSummary.forEach((reg) => {
      var regionData = { ...reg };
      regionData.Code = regionCodes[reg.Region];
      regionData.RegionName = getRegionName(reg.Region, regionList);
      regionData.PrevResourceCount = regionData.ResourceCount;
      response.RegionSummary.push(regionData);
    });
  }
  if (newData && isArrayWithLength(newData.ResourceSummary)) {
    let resourceList = isAzure ? azureResourceList : awsResourceList;
    newData.ResourceSummary.forEach((res) => {
      var resourceData = { ...res };
      resourceData.Name = resourceList[res.ResourceType]
        ? resourceList[res.ResourceType]
        : res.ResourceType;
      resourceData.PrevResourceCount = resourceData.ResourceCount;
      resourceData.ResourceImage = isAzure
        ? getMatchKey(AZURE_RESOURCE_IMAGES_KEYS, res.ResourceType)
        : res.ResourceType;
      response.ResourceSummary.push(resourceData);
    });
  }
  return response;
};

const requestGetAllResources = () => {
  return {
    type: GET_ALL_RESOURCES_REQUEST,
  };
};

const receiveAllResources = (payload) => {
  return {
    type: GET_ALL_RESOURCES_SUCCESS,
    payload,
  };
};

const getAllResourcesError = (payload) => {
  return {
    type: GET_ALL_RESOURCES_FAILED,
    payload,
  };
};

export const getAllResources = (orgId, accountId) => {
  var payload = {
    Org: [orgId],
    Accounts: [accountId],
  };

  const test = encodeURI(JSON.stringify(payload));
  const params = "Filters=" + test;
  return (dispatch) => {
    dispatch(requestGetAllResources());
    getAllResourcesApi(params)
      .then((data) => {
        dispatch(receiveAllResources(data));
      })
      .catch((err) => {
        dispatch(getAllResourcesError(err.body));
      });
  };
};

const addResourcesToDeptRequest = (payload) => {
  return {
    type: ADD_RESOURCES_TO_DEPT_REQUEST,
    payload,
  };
};

const addResourcesToDeptSuccess = (payload) => {
  return {
    type: ADD_RESOURCES_TO_DEPT_SUCCESS,
    payload,
  };
};

const addResourcesToDeptError = (payload) => {
  return {
    type: ADD_RESOURCES_TO_DEPT_FAILED,
    payload,
  };
};

export const addResourcesToDept = (resources, orgId, deptId) => {
  return (dispatch) => {
    dispatch(addResourcesToDeptRequest());
    addResourcesToDeptApi(resources, orgId, deptId)
      .then((data) => {
        dispatch(addResourcesToDeptSuccess(data));
      })
      .catch((err) => {
        dispatch(addResourcesToDeptError(err.body));
      });
  };
};

export const setSelectedDepartment = (selectedDept) => ({
  type: SET_SELECTED_DEPT,
  payload: selectedDept,
});

export const clearSelectedDept = () => ({
  type: CLEAR_SELECTED_DEPT,
});

export const clearDeptSaveData = () => ({
  type: CLEAR_DEPARTMENT_DATA,
});

export const clearFirstRunData = () => ({
  type: CLEAR_FIRST_RUN_DATA,
});

export const updateDepartmentSearchKey = (key) => ({
  type: UPDATE_DEPARTMENT_SEARCH_KEY,
  payload: key,
});

export const setHideCloseButton = (payload) => ({
  type: SET_HIDE_CLOSE_BUTTON,
  payload: payload,
});

export const setSelectedCloudProvider = (payload) => ({
  type: SET_SELECTED_CLOUD_PROVIDER,
  payload: payload,
});

export const clearDiscoverySummary = () => ({
  type: CLEAR_DISCOVERY_SUMMARY,
});

export const setAccountType = (payload) => ({
  type: SET_ACCOUNT_TYPE,
  payload: payload,
});

export const orgHierarchyRequest = () => ({
  type: ORG_HIERARCHY_REQUEST,
});

export const orgHierarchySuccess = (payload) => ({
  type: ORG_HIERARCHY_SUCCESS,
  payload: payload,
});

export const orgHierarchyError = (payload) => ({
  type: ORG_HIERARCHY_ERROR,
  payload: payload,
});

const onboardMemberAccountRequest = () => {
  return {
    type: ONBOARD_MEMBER_ACCOUNT_REQUEST,
  };
};

const onboardMemberAccountSuccess = (payload) => {
  return {
    type: ONBOARD_MEMBER_ACCOUNT_SUCCESS,
    payload,
  };
};

const onboardMemberAccountError = (payload) => {
  return {
    type: ONBOARD_MEMBER_ACCOUNT_FAILED,
    payload,
  };
};

const offboardMemberAccountRequest = () => {
  return {
    type: OFFBOARD_MEMBER_ACCOUNT_REQUEST,
  };
};

const offboardMemberAccountSuccess = (payload) => {
  return {
    type: OFFBOARD_MEMBER_ACCOUNT_SUCCESS,
    payload,
  };
};

const offboardMemberAccountError = (payload) => {
  return {
    type: OFFBOARD_MEMBER_ACCOUNT_FAILED,
    payload,
  };
};

export const toggleViewType = (viewType) => ({
  type: TOGGLE_VIEW_TYPE,
  viewType,
});
