import React from "react";
import classnames from "classnames";
import { Col, Row } from "reactstrap";
import { useTranslation } from "react-i18next";

import ExternalLink from "../../../../components/ExternalLink/ExternalLink";
import ConfigureAzureAccess from "./ConfigureAzureAccess";
import { azurePermissionsLink } from "../../../../utils/app-links";

import s from "../NewFirstRun.module.scss";

const ConfigureAzureSubscription = ({ parentNext }) => {
  const { t } = useTranslation(["firstRun"]);

  return (
    <div
      className={classnames(
        s.welcomeDivLarge,
        s.ContentBackground,
        s.ConnectAccountContainer
      )}
    >
      <Row className="px-2 px-4">
        <Col lg={6} className="text-center">
          <div className="text-center">
            <img loading="lazy"
              src={"/images/dashboard/securely-connect-account.png"}
              alt="day2"
              className={s.ConnectAccountImage}
            />
          </div>
          <Row className={s.LinksMargin}>
            <Col xl={3}></Col>
            <Col xl={5} className="p-0">
              <ExternalLink
                text={t("permissionsDAY2need")}
                size="medium"
                externalLink={azurePermissionsLink}
              />
            </Col>
            <Col xl={3}></Col>
          </Row>
        </Col>
        <Col lg={6}>
          <ConfigureAzureAccess isFRE={true} parentNext={parentNext} />
        </Col>
      </Row>
    </div>
  );
};

export default ConfigureAzureSubscription;
