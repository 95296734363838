import React, { useEffect } from "react";
import { Formik } from "formik";
import { Input, FormGroup, InputGroup } from "reactstrap";
import Label from "../../../../components/Label";
import CustomSelect from "../../../../components/CustomSelect";
import classnames from "classnames";
import { isArrayWithLength } from "../../../../utils/array-methods";
import { isEmptyObject } from "../../../../utils/object-methods";
import { INPUT_TYPES } from "../../../../utils/app-constants.json";
import { validateForm } from "../../utils";
import s from "./CommonFormRenderer.module.scss";
import Tagify from "@yaireo/tagify";
import "@yaireo/tagify/dist/tagify.css";
import CopyText from "../../../../components/CopyText/CopyText";

const CommonFormRenderer = ({
  fields,
  handleUpdateFields,
  onSubmit,
  isDisabled,
  className,
  validateOnMount,
  renderedInsideModal = false,
}) => {
  const allValues =
    isArrayWithLength(fields) &&
    fields.reduce((obj, item) => {
      return {
        ...obj,
        [item["key"]]: item.value,
      };
    }, {});

  const handleValidateForm = (values) => {
    const updatedValues = { ...values };
    Object.keys(updatedValues).forEach((key) => {
      updatedValues[key] =
        updatedValues[key] && typeof updatedValues[key] === "string"
          ? updatedValues[key].replace(/\n/g, "")
          : updatedValues[key];
    });
    const errors = validateForm(updatedValues, fields);
    const hasError = !isEmptyObject(errors);
    handleUpdateFields(updatedValues, hasError);
    return errors;
  };

  useEffect(() => {
    if (isArrayWithLength(fields)) {
      fields.forEach((field) => {
        if (field.isTaggable && !field.viewOnly) {
          const triggerOptions = isArrayWithLength(field.triggerOptions)
            ? field.triggerOptions.map((option) => {
                return {
                  value: option.Key,
                  text: option.Label,
                };
              })
            : [];
          if (isArrayWithLength(triggerOptions)) {
            const input = document.getElementById(field.id);
            const tagify = new Tagify(input, {
              mode: "mix",
              enforceWhitelist: true,
              mixTagsInterpolator: "{}",
              duplicates: true,
              editTags: false,
              id: `field-${field.id}`,
              pattern: /@/, // <--  Text starting with @ or # (if single, String can be used here)
              tagTextProp: "text",
              whitelist: triggerOptions.map(function (item) {
                return typeof item == "string" ? { value: item } : item;
              }),
              dropdown: {
                enabled: 0,
                position: "text", // <-- render the suggestions list next to the typed text ("caret")
                mapValueTo: "text", // <-- similar to above "tagTextProp" setting, but for the dropdown items
                highlightFirst: true, // automatically highlights first sugegstion item in the dropdown
              },
            });
            // A good place to pull server suggestion list accoring to the prefix/value
            tagify.on("input", function (e) {
              var prefix = e.detail.prefix;
              if (prefix) {
                if (e.detail.value.length > 1)
                  tagify.dropdown.show(e.detail.value);
              }
            });
          }
        }
      });
    }
  }, [fields]);

  useEffect(() => {
    return () => {
      Object.entries(localStorage)
        .map((x) => x[0])
        .filter((x) => x.includes("@yaireo/tagify/"))
        .forEach((x) => localStorage.removeItem(x));
    };
  }, []);

  const conditionalSelectProps = renderedInsideModal
    ? {
        selectMenuStyles: {
          maxHeight: "200px",
        },
        menuPortalTarget: document.body,
        menuPortalStyles: {
          zIndex: 9999,
        },
      }
    : {};

  return (
    <div className={classnames(className)}>
      {!isEmptyObject(allValues) && (
        <Formik
          initialValues={allValues}
          validateOnBlur={true}
          validate={(values) => {
            return handleValidateForm(values);
          }}
          onSubmit={onSubmit && onSubmit}
          validateOnMount={validateOnMount}
          enableReinitialize
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <form
              onSubmit={onSubmit ? handleSubmit : (e) => e.preventDefault()}
            >
              {isArrayWithLength(fields) &&
                fields.map((field, index) => {
                  const key = field.key;
                  const isClearable = field.hasOwnProperty("isClearable")
                    ? field.isClearable
                    : true;
                  const value =
                    values[key] &&
                    (Array.isArray(values[key]) ? values[key] : [values[key]]);
                  const showError = value && (touched[key] || validateOnMount);
                  return (
                    <FormGroup
                      key={field.id}
                      className={classnames(index > 0 && "mt-3", "mb-0")}
                    >
                      {field.label && (
                        <Label
                          required={field.required}
                          htmlFor={field.id}
                          className="mc-text-base-bold"
                        >
                          {field.label}
                        </Label>
                      )}
                      {field.viewOnly ? (
                        <div className="mc-text-base-regular">
                          {field.value}
                        </div>
                      ) : (
                        <div>
                          <InputGroup
                            className={classnames(
                              errors[key] && showError && "input-error",
                              field.allowCopy && "align-items-center"
                            )}
                          >
                            {field.type === INPUT_TYPES.SELECT ? (
                              <CustomSelect
                                value={value}
                                onChange={(value) => {
                                  setFieldValue(field.key, value);
                                }}
                                options={field.options}
                                placeholder={field.placeholder}
                                isClearable={isClearable}
                                isMultiFilter={false}
                                isDisabled={isDisabled}
                                isMulti={field.isMulti}
                                extraStyles={field.extraStyles}
                                className="customSelect mc-text-base-regular"
                                {...conditionalSelectProps}
                                isFormField={true}
                              />
                            ) : (
                              <>
                                <Input
                                  className={classnames(
                                    "form-control mc-text-base-regular",
                                    field.isTaggable && s.TaggableInput,
                                    field.allowCopy && "mr-2"
                                  )}
                                  value={values[key]}
                                  onChange={handleChange}
                                  type={field.type}
                                  required={field.required}
                                  name={key}
                                  placeholder={field.placeholder}
                                  onBlur={handleBlur}
                                  id={field.id}
                                  autoComplete={
                                    (field.type === "password" &&
                                      "new-password") ||
                                    field.autoComplete
                                  }
                                  disabled={isDisabled || field.isDisabled}
                                />
                                {field.allowCopy && (
                                  <CopyText
                                    showText={false}
                                    text={values[key]}
                                    id={field.id}
                                  />
                                )}
                              </>
                            )}
                          </InputGroup>

                          {field.helpText && (
                            <>
                              <span
                                className={classnames(
                                  s.helpText,
                                  "mc-text-base-regular"
                                )}
                              >
                                {field.helpText}
                              </span>
                            </>
                          )}
                          {errors[key] && showError && (
                            <div className="warningText mc-text-base-regular">
                              <i className="fa fa-exclamation-circle" />{" "}
                              {errors[key]}
                            </div>
                          )}
                        </div>
                      )}
                    </FormGroup>
                  );
                })}
            </form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default CommonFormRenderer;
