import { createApi } from "@reduxjs/toolkit/query/react";
import {
  HEALTH_EVENT,
  HEALTH_EVENT_AFFECTED_RESOURCES,
  ORG_BASE_URL,
} from "../../../../config/service";
import { axiosBaseQuery } from "../../../../config/customBaseQuery";
import {
  WEB_REQUEST_TYPE,
  getApiService,
} from "../../../../helpers/apiService";

export const fetchAffectedResources = (eventId, limit, offset, searchKey) => {
  let params;
  params = `?Resource=${searchKey}`;
  if (offset) {
    params = params + `&Offset=${offset}`;
  }
  if (limit) {
    params = params + `&Limit=${limit}`;
  }
  return getApiService(
    WEB_REQUEST_TYPE.GET,
    `${ORG_BASE_URL}${HEALTH_EVENT}${eventId}${HEALTH_EVENT_AFFECTED_RESOURCES}${params}`
  );
};

export const healthEventDetailsApi = createApi({
  reducerPath: "healthEventDetails",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getHealthEventDetails: builder.query({
      query: (eventId) => {
        return {
          url: `${ORG_BASE_URL}${HEALTH_EVENT}${eventId}`,
          method: "GET",
        };
      },
    }),
  }),
});

export const { useGetHealthEventDetailsQuery } = healthEventDetailsApi;
