import { createApi } from "@reduxjs/toolkit/query/react";
import {
  HEALTH_EVENT_CONFIGURATION,
  ORG_BASE_URL,
} from "../../../config/service";
import { axiosBaseQuery } from "../../../config/customBaseQuery";
import { WEB_REQUEST_TYPE } from "../../../helpers/apiService";

export const healthEventConfigurationApi = createApi({
  reducerPath: "healthEventConfigurations",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getHealthEventConfiguration: builder.query({
      query: ({ tenantId }) => {
        return {
          url: `${ORG_BASE_URL}${tenantId}${HEALTH_EVENT_CONFIGURATION}`,
          method: "GET",
        };
      },
    }),
    createHealthEventConfiguration: builder.mutation({
      query: (data) => {
        const { tenantId, body } = data;
        return {
          url: `${ORG_BASE_URL}${tenantId}${HEALTH_EVENT_CONFIGURATION}`,
          method: WEB_REQUEST_TYPE.POST,
          body,
        };
      },
    }),
  }),
});

export const {
  useGetHealthEventConfigurationQuery,
  useCreateHealthEventConfigurationMutation,
} = healthEventConfigurationApi;
