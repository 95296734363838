import classnames from "classnames";
import React from "react";
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import s from "./Popup.module.scss";

class Popup extends React.Component {
  render() {
    const {
      isOpen,
      toggle,
      className,
      header,
      content,
      footer,
      darkTheme,
      isLoading,
      contentClass,
      headerClassName,
      closeIconClassName,
      footerClassName,
      size="md"
    } = this.props;

    return (
      <Modal
        className={classnames("popup", className, s.modal)}
        isOpen={isOpen}
        size={size}
      >
        {(header || headerClassName) && (
          <ModalHeader className={classnames(s.ModalHeader, headerClassName)}>
            <Row>
              <Col md={10} xl={11}>{header}</Col>
              <Col className="close-popup" md={2} xl={1}>
                <i
                  onClick={toggle}
                  className={classnames(
                    "fi flaticon-multiply",
                    closeIconClassName
                  )}
                />
              </Col>
            </Row>
          </ModalHeader>
        )}
        {isLoading ? (
          <div className={s.loading}>
            <i className="fa fa-spin fa-spinner" />
          </div>
        ) : (
          <ModalBody
            className={classnames(
              s.ModalBody,
              darkTheme ? "bg-oxford-blue" : "bg-white",
              contentClass
            )}
          >
            {content}
          </ModalBody>
        )}
        {footer && (
          <ModalFooter className={classnames(s.ModalFooter, footerClassName)}>
            {footer}
          </ModalFooter>
        )}
      </Modal>
    );
  }
}

export default Popup;
