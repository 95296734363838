import {
  GET_ALL_REPORTS_REQUEST,
  GET_ALL_REPORTS_SUCCESS,
  GET_ALL_REPORTS_FAILURE,
  RESET_ALL_REPORTS,
  SET_SELECTED_REPORT,
  RESET_REPORTS_VIEW,
  UPDATE_REPORT_SEARCH_KEY,
  SET_MANAGEMENT_SELECTED_REPORT,
} from "../actionTypes/reports";

import { isArrayWithLength } from "../../../utils/array-methods";
import { CLOUDPROVIDERS } from "../../../utils/app-constants.json";

const initialState = {
  reportsList: null,
  isFetchingReports: false,
  selectedReport: null,
  reportSearchKey: "",
};

const reportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_REPORTS_REQUEST:
      return {
        ...state,
        isFetchingReports: true,
      };
    case GET_ALL_REPORTS_SUCCESS:
      return {
        ...state,
        isFetchingReports: false,
        reportsList: action.payload,
      };
    case GET_ALL_REPORTS_FAILURE:
      return {
        ...state,
        isFetchingReports: false,
        errorMessage: action.payload,
      };
    case RESET_ALL_REPORTS:
      return {
        ...state,
        reportsList: null,
      };
    case UPDATE_REPORT_SEARCH_KEY:
      return {
        ...state,
        reportSearchKey: action.payload,
      };
    case SET_SELECTED_REPORT:
      const report =
        isArrayWithLength(state.reportsList) &&
        state.reportsList.find((r) => r.reportPath === action.payload);
      const selectedReport = {
        title: report.name,
        description: report.description,
        noDataMessage: report.noDataMessage,
        cloudProvider: report.cloudProvider
          ? report.cloudProvider
          : CLOUDPROVIDERS.AWS,
      };
      return {
        ...state,
        selectedReport: selectedReport,
      };
    case SET_MANAGEMENT_SELECTED_REPORT:
      const selectedManagementReport = {
        title: action.payload.name,
        description: action.payload.description,
        noDataMessage: action.payload.noDataMessage,
        cloudProvider: action.payload.cloudProvider
          ? action.payload.cloudProvider
          : CLOUDPROVIDERS.AWS,
      };
      return {
        ...state,
        selectedReport: selectedManagementReport,
      };
    case RESET_REPORTS_VIEW:
      return {
        ...state,
        showInstanceSummaryReport: initialState.showInstanceSummaryReport,
        showPatchSummaryReport: initialState.showPatchSummaryReport,
        showPatchGroupComplianceReport:
          initialState.showPatchGroupComplianceReport,
      };
    default:
      return state;
  }
};

export default reportsReducer;
