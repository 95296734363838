import React from "react";

import styles from "./SelectableCard.module.scss";

const SelectableCard = ({
  content,
  selected,
  handleSelect,
  className = "",
  disabled,
}) => {
  return (
    <div
      className={`${styles.cardLayout} ${
        selected && styles.selected
      } ${className} ${disabled && styles.disabled}`}
      onClick={!disabled ? handleSelect : null}
    >
      {content}
    </div>
  );
};

export default SelectableCard;
