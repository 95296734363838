import classNames from "classnames";
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import styles from "./McAlert.module.scss";

const McAlert = ({
  type = "info",
  content,
  className = "",
  hideIcon = false,
  overrideIcon = null,
  contentClassname = "",
  iconClassName = ""
}) => {
  const icon = useMemo(() => {
    switch (type) {
      case "warning":
        return "fa fa-exclamation-triangle text-warning";
      case "error":
        return "fa fa-exclamation-circle text-danger";
      case "success":
        return "fa fa-check-circle text-success";
      default:
        return "fa fa-info-circle text-info";
    }
  }, [type]);

  return (
    <div
      className={classNames(
        styles.mcAlert,
        styles[type],
        "p-3 mc-text-base-medium",
        className
      )}
    >
      {!hideIcon && !overrideIcon && (
        <div className={classNames(styles.icon, "mr-3")}>
          <i className={classNames(icon, iconClassName)} />
        </div>
      )}
      {!!overrideIcon && overrideIcon}
      <div className={contentClassname}>{content}</div>
    </div>
  );
};

export default McAlert;

McAlert.propTypes = {
  type: PropTypes.oneOfType(["info", "warning", "error", "success"]),
  className: PropTypes.string,
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};
