import React, { useState } from "react";
import classnames from "classnames";
import { Badge } from "reactstrap";
import ExternalLink from "../../../components/ExternalLink/ExternalLink";
import MCButton from "../../../components/MCButton/MCButton";
import { useTranslation } from "react-i18next";
import styles from "./NewFirstRun.module.scss";
import { ORG_FEATURE_LAYOUT_TYPE } from "../../organizations-multi-tenancy/mt-constants";

const MIN_FEATURES_TO_SHOW = 2;

const Day2FeatureCard = ({
  featurePlan,
  handleClick,
  isLoading = false,
  selectedFeaturePlan,
  layout = ORG_FEATURE_LAYOUT_TYPE.FRE,
  disabled = false,
}) => {
  const { t } = useTranslation(["common"]);
  const isCreateLayout = layout === ORG_FEATURE_LAYOUT_TYPE.CREATE_ORG;
  const [showMore, setShowMore] = useState(false);

  const toggleMoreButton = () => {
    setShowMore(!showMore);
  };

  let isSelectedPlan = false;

  if (selectedFeaturePlan) {
    if (isCreateLayout) {
      isSelectedPlan = selectedFeaturePlan === featurePlan.type;
    } else {
      isSelectedPlan = selectedFeaturePlan.type === featurePlan.type;
    }
  }

  let buttonTitle = isSelectedPlan
    ? isCreateLayout
      ? t("selected")
      : `${t("selecting")}`
    : t("select");

  const handleSelect = (feature) => {
    let param = feature;

    if (isCreateLayout) {
      param = feature.type;
    }

    handleClick(param);
  };

  let buttonColor = isCreateLayout && isSelectedPlan ? "none" : "mc-blue";

  const resourceLink = (
    <div className={classnames(isCreateLayout && "mt-1")}>
      <ExternalLink
        text={t("viewPermissions")}
        externalLink={featurePlan.permissionLink}
        size="medium"
        className={classnames(!isCreateLayout ? "mt-4" : "mt-2")}
      />
    </div>
  );

  const headerContent = (
    <>
      <div className="mc-h4-semi-bold mb-1">{featurePlan.title}</div>
      <Badge
        className={classnames(styles.featureTypeBadge, "mr-xs px-4 mb-3")}
        color={featurePlan.badgeColor}
      >
        {featurePlan.badgeText}
      </Badge>
    </>
  );

  return (
    <div
      className={classnames(
        styles.featureCardLayout,
        "p-4",
        isCreateLayout && "w-100",
        isLoading && "disabled-cursor-no-opacity"
      )}
    >
      <div className="d-flex flex-column justify-content-between h-100">
        <div>
          {isCreateLayout ? (
            <div className="d-flex justify-content-between">
              <div>{headerContent}</div>
              {resourceLink}
            </div>
          ) : (
            headerContent
          )}

          <ul
            className={classnames(
              "mc-text-base-regular",
              isCreateLayout && "mb-0"
            )}
          >
            {featurePlan.features.map((featureName, index) => {
              const isLastStep = !showMore
                ? index === MIN_FEATURES_TO_SHOW
                : index === featurePlan.features.length - 1;

              // minimum features to display, rest is hidden
              const showDefaultItem = index <= MIN_FEATURES_TO_SHOW;

              // show hidden items if user click showMore button
              const showRemainingItems =
                showMore && index > MIN_FEATURES_TO_SHOW && isCreateLayout;

              // show all items if it's fre layout
              const isFreLayout = layout === ORG_FEATURE_LAYOUT_TYPE.FRE;

              if (showDefaultItem || showRemainingItems || isFreLayout) {
                return (
                  <li
                    key={featureName}
                    className={"display-flex align-items-baseline mb-2"}
                  >
                    <i
                      className="fa fa-check-circle-o mr-2"
                      aria-hidden="true"
                    />
                    <div className="d-flex">
                      {featureName}
                      {isLastStep && isCreateLayout && (
                        <button
                          className={classnames(
                            styles.showMoreButton,
                            "d-inline ml-2"
                          )}
                          onClick={toggleMoreButton}
                        >
                          {!showMore
                            ? t("label.showMore")
                            : t("label.showLess")}
                        </button>
                      )}
                    </div>
                  </li>
                );
              }

              return null;
            })}
          </ul>
        </div>
        <div>
          {!isCreateLayout && resourceLink}
          <MCButton
            size="md"
            className={classnames("mt-2 w-100", {
              [styles.selectedButton]: isCreateLayout && isSelectedPlan,
            })}
            color={buttonColor}
            text={
              <>
                {isCreateLayout && isSelectedPlan && (
                  <i className="fa fa-check mr-2"></i>
                )}
                {buttonTitle}
              </>
            }
            disabled={disabled || isLoading}
            onClick={() => handleSelect(featurePlan)}
          />
        </div>
      </div>
    </div>
  );
};

export default Day2FeatureCard;
