import React from "react";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { AUTH_TYPE } from "../../constants";

const icommon = i18next.getFixedT(null, "common");

const RedirectTo = ({ type = AUTH_TYPE.LOGIN }) => {
  const { t } = useTranslation("loginForm");
  if (type === AUTH_TYPE.SIGNUP) {
    return (
      <div className="mc-h6-regular clearfix mt-3">
        {t("register1")}{" "}
        <Link className="mt-sm mt-2" to="/signup">
          {t("signUp")}
        </Link>{" "}
        {icommon("here")}
      </div>
    );
  }
  return (
    <div className="mc-h6-regular clearfix mt-3">
      {t("takeMeto")}
      <Link className="ml-2" to="/login">
        {t("loginTitle")}
      </Link>
    </div>
  );
};

export default RedirectTo;
