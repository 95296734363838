import React from "react";
import classnames from "classnames";
import styles from "./RadioButton.module.scss";
import { UncontrolledTooltip } from "reactstrap";
import Label from "../Label";

const RadioButton = ({
  text,
  checked,
  onChange,
  disabled = false,
  tooltip,
  tooltipId,
  id,
  className,
  tooltipPosition = "right",
  disabledLabelClassName = "",
  testId,
}) => {
  return (
    <Label
      htmlFor={id}
      className={classnames(
        styles.Container,
        className,
        disabled && disabledLabelClassName
      )}
    >
      {text}
      <input
        type="radio"
        onChange={onChange}
        checked={checked}
        disabled={disabled}
        id={id}
        data-testid={testId}
      />
      <span
        className={classnames(styles.RadioButton, [
          disabled && styles.DisabledRadioButton,
        ])}
      ></span>
      {tooltip && (
        <>
          <span className="ml-2">
            <i className="fa fa-info-circle color-gray" id={tooltipId}></i>
          </span>
          <UncontrolledTooltip
            placement={tooltipPosition}
            autohide={false}
            target={tooltipId}
          >
            {tooltip}
          </UncontrolledTooltip>
        </>
      )}
    </Label>
  );
};

export default RadioButton;
