import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Row, Col, Button } from "reactstrap";
import styles from "./Instances.module.scss";
import classnames from "classnames";
import { withTranslation } from "react-i18next";
import TerminalApp from "../../../../components/SessionManager";
import {
  startSession,
  resumeSession,
  terminateSession,
  getInstanceDetails,
} from "../../actions/instance";
import RemoteSessionDemo from "./InstanceRemoteSessionDemo";
import config from "../../../../config";
import Loader from "../../../../components/Loader/Loader";
import { withRouter } from "@hocs/withRouter";

class InstanceRemoteSession extends Component {
  constructor(props) {
    super(props);
    this.state = {
      StreamUrl: null,
      TokenValue: null,
      SessionId: null,
      InstanceId: null,
    };
  }
  componentDidMount() {
    let instanceId = this.props.match.params.instanceId;
    let sessionId = this.props.match.params.sessionId;
    this.props.getInstanceDetails(instanceId);
    this.setState({
      InstanceId: instanceId,
      SessionId: sessionId,
    });
    if (!sessionId) {
      this.props.startSession(instanceId);
    }
  }
  UNSAFE_componentWillReceiveProps(props) {
    if (props.startSessionData) {
      this.setState({
        TokenValue: props.startSessionData.TokenValue,
        StreamUrl: props.startSessionData.StreamUrl,
        SessionId: props.startSessionData.SessionId,
      });
    }
    if (props.terminateSessionData) {
      window.close();
    }
  }

  render() {
    const {
      t,
      terminateSessionRequest,
      terminateSessionFailureMessage,
      startSessionFailureMessage,
      instanceDetails,
      startSessionRequest,
    } = this.props;
    const { StreamUrl, TokenValue, SessionId, InstanceId } = this.state;
    const isRequesting = startSessionRequest || terminateSessionRequest;
    const sessionFailureMessage = startSessionFailureMessage
      ? startSessionFailureMessage
      : terminateSessionFailureMessage;

    const renderContent = () => {
      if (StreamUrl && TokenValue) {
        if (terminateSessionRequest) {
          return (
            <div className={styles.terminateText}>{t("terminatingSession")}</div>
          );
        }

        if (config.demoApp) {
          return <RemoteSessionDemo />;
        }

        return (
          <TerminalApp
            StreamUrl={StreamUrl}
            TokenValue={TokenValue}
            SessionId={SessionId}
            InstanceId={InstanceId}
          />
        );
      } else {
        if (sessionFailureMessage) {
          return <div className={styles.terminateText}>{sessionFailureMessage}</div>;
        }

        if (isRequesting) {
          return <Loader className={styles.loading} />;
        }

        return (
          <div className={styles.terminateText}>{t("errorConnectInstance")}</div>
        );
      }
    };
    return (
      <React.Fragment>
        <Helmet>
          <title>{t("Day2") + InstanceId + t("remoteConsoleSession")}</title>
        </Helmet>
        <div>
          <Row className={styles.header}>
            <Col sm={8}>
              <h5 className="mt-1">
                <img loading="lazy"
                  src="/images/mc-logo.png"
                  alt="MontyCloud"
                  className="mr-2"
                />
                {t("remoteConsoleSessionFor")}
                <strong>
                  {instanceDetails && instanceDetails.name
                    ? `${instanceDetails.name} (${InstanceId})`
                    : InstanceId}
                </strong>
              </h5>
            </Col>
            <Col sm={4}>
              <Button
                className={classnames("btn btn-sm mr-2 pull-right", styles.red)}
                onClick={() =>
                  this.props.terminateSession(InstanceId, SessionId)
                }
              >
                {t("closeSession")}
              </Button>
            </Col>
          </Row>
          {renderContent()}
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = ({ computeInstance }) => {
  const {
    startSessionData,
    resumeSessionData,
    terminateSessionRequest,
    terminateSessionData,
    isFetchingInstanceSessionLog,
    instanceSessionLog,
    hasFetchedInstanceSessionLog,
    startSessionFailureMessage,
    terminateSessionFailureMessage,
    instanceDetails,
    startSessionRequest,
  } = computeInstance;
  return {
    startSessionData,
    resumeSessionData,
    terminateSessionRequest,
    terminateSessionData,
    isFetchingInstanceSessionLog,
    instanceSessionLog,
    hasFetchedInstanceSessionLog,
    startSessionFailureMessage,
    terminateSessionFailureMessage,
    instanceDetails,
    startSessionRequest,
  };
};
export default withRouter(
  connect(mapStateToProps, {
    startSession,
    resumeSession,
    terminateSession,
    getInstanceDetails,
  })(withTranslation(["ec2InstanceList"])(InstanceRemoteSession))
);
