import { combineReducers } from "redux";
import navigation from "./navigation";
import globalConfig from "./global-config";
import alerts from "./alerts";
import layout from "./layout";
import products from "./products";
import notification from "./notifications";
import tour from "./tour";
import accounts from "../modules/account/reducers/accounts";
import addAccount from "../modules/account/reducers/account";
import auth from "../modules/authentication/reducers/auth";
import signup from "../modules/authentication/reducers/signup";
import toggle from "../modules/account/reducers/toggle";
import instances from "../modules/instance/reducers/instances";
import volumes from "../modules/volume/reducers/volumes";
import snapshots from "../modules/snapshot/reducers/snapshots";
import serverGroups from "../modules/patch/reducers/server-groups";
import patchPolicies from "../modules/patch-policy/reducers/patch-policies";
import securityPolicies from "../modules/security-policy/reducers/security-policies";
import inventoryPolicies from "../modules/inventory-policy/reducers/inventory-policies";
import costPolicies from "../modules/cost-policy/reducers/cost-policies";
import patchPolicy from "../modules/patch-policy/reducers/patch-policy";
import costPolicy from "../modules/cost-policy/reducers/cost-policy";
import computeInstance from "../modules/instance/reducers/instance";
import task from "../modules/task/reducers/task";
import createUser from "../modules/task/reducers/user";
import attachPolicy from "../modules/task/reducers/policy";
import dasParameters from "../modules/task/reducers/parameters";
import tasks from "../modules/tasks/reducers/tasks";
import taskDetails from "../modules/tasks/reducers/task";
import taskOutput from "../modules/tasks/reducers/task-output";
import userData from "../modules/user-data/reducers/user-data";
import regions from "../modules/region/reducers/regions";
import integrationReducer from "../modules/integrations/reducers/integrationReducer";
import sso from "../modules/integrations/reducers/sso";
import serverGroup from "../modules/server-group/reducers/server-group";
import firstRun from "../modules/first-run/reducers/firstRun";
import newFirstRun from "../modules/new-first-run/reducers/new-first-run";
import organization from "../modules/organization/reducers/organization";
import application from "../modules/organization/reducers/application";
import department from "../modules/organization/reducers/department";
import environment from "../modules/organization/reducers/environment";
import resource from "../modules/organization/reducers/resource";
import rules from "../modules/organization/reducers/rules";
import classification from "../modules/organization/reducers/classification";
import appMonitoring from "../modules/organization/reducers/monitoring";
import appParameters from "../modules/organization/reducers/parameters";
import appStorage from "../modules/organization/reducers/storage";
import blueprint from "../modules/blueprints/reducers/blueprint";
import blueprints from "../modules/blueprints/reducers/blueprints";
import blueprintParameter from "../modules/blueprints/reducers/parameter";
import launchConfiguration from "../modules/blueprints/reducers/configurations";
import patchDeployment from "../modules/patch-deployment/reducers/patchDeployment";
import vulnerabilityScanning from "../modules/vulnerability-scanning/reducers/vulnerabilityScanning";
import reports from "../modules/reports/reducers/reportsReducer";
import reportFilter from "../modules/reports/reducers/reportFilterReducer";
import reportCriteria from "../modules/reports/reducers/reportCriteriaReducer";
import feedback from "../components/Feedback/reducers/feedback";
import users from "../modules/users-roles/reducers/users";
import roles from "../modules/users-roles/reducers/roles";
import mfaReducer from "../modules/users-roles/reducers/mfa";
import adminTask from "../modules/admin-tasks/reducers/task";
import adminTasks from "../modules/admin-tasks/reducers/tasks";
import taskParameter from "../modules/admin-tasks/reducers/parameter";
import monitoring from "../modules/monitoring/reducers/monitoring";
import payment from "../modules/payment/reducers/payment";
import desiredStatePolicies from "../modules/desiredstate/reducers/desired-state-policies";
import desiredStatePolicy from "../modules/desiredstate/reducers/desired-state-policy";
import desiredState from "../modules/desiredstate/reducers/desired-state";
import dateRangeFilter from "../modules/reports/reducers/dateRangeFilter";
import imagesServerOperation from "../modules/image/reducers/images";
import activateOnprem from "../modules/activate-onprem/reducers/activate-onprem";
import configureCloudWatch from "../modules/instance/reducers/configure-cloud-watch";
import commons from "../modules/commons/reducers/commons";
import allResourcesReducer from "../modules/commons/reducers/allResources";
import allUsers from "../modules/commons/reducers/users";
import commonReducer from "../modules/commons/reducers/commonReducer";
import day2Bots from "../modules/day2-bots/reducers/day2-bots";
import botInsight from "../modules/day2-bots/reducers/bot-insight";
import subscription from "../modules/authentication/reducers/subscription";
import resourceOptimization from "../modules/insights/reducers/optimization";
import inventory from "../modules/organization/reducers/inventory";
import optimizationReducer from "../modules/insights/reducers/optimizationReducer";
import allReports from "../modules/reports/reducers/reports";
import supportTicket from "../components/SupportTicket/reducers/supportTicket";
import accountReducer from "../modules/account/reducers/accountReducer";
import s3 from "../modules/s3/reducers/s3";
import applicationTaskReducer from "../modules/organization/reducers/applicationTaskReducer";
import workspaceReducer from "../modules/workspace/reducers/workspaceReducer";
import notebookInstanceReducer from "../modules/notebookInstance/reducers/notebookInstanceReducer";
import tokenReducer from "../modules/authentication/reducers/token";
import assessmentsReducer from "../modules/assessments/reducers/assessments";
import detailsReducers from "../modules/assessments/reducers/details";
import assessmentDetailsReducer from "../modules/assessments/reducers/assessmentDetails";
import assessmentReviewReducer from "../modules/assessments/reducers/assessmentReview";
import assessmentChecksSummaryReducer from "../modules/assessments/reducers/checksSummary";
import dashboard from "../modules/dashboard/reducers/dashboard";
import reportSchedule from "../modules/reports/reducers/reportSchedule";
import assessmentMilestonesReducer from "../modules/assessments/reducers/milestones";
import organizationManager from "./org-manager";
import mapProjectReviewReducer from "../modules/map-project/reducers/mapProjectReview";
import { mapProjectDetails } from "../modules/map-project/reducers/mapProjectDetails";
import mapProjects from "../modules/map-project/reducers/mapProjects";
import foundationalBestPracticesReducer from "../modules/assessments/reducers/foundationalBestPractices";
import blueprintSharedCatalog from "../modules/blueprints/reducers/sharedCatalog";
import marvinReducer from "../modules/marvin/reducers/marvinReducer";
import awsAccounts from "../modules/first-run/reducers/awsAccounts";
import billingGroupsList from "../modules/cost/abc/reducers/billingGroupsListReducers";
import billingGroupAccountsList from "../modules/cost/abc/reducers/billingGroupAccountsListRedusers";
import createBillingGroup from "../modules/cost/abc/reducers/createBillingGroupReducers";
import costChartReducer from "../modules/cost/reducers/costChart";
import { tenantCategoryAPI } from "../modules/tenant-category/apiService/tenant-category-api-service";
import { findingSummaryApi } from "../modules/assessments/apiService/assessment-findings-api-service";
import { milestoneFindingSummaryApi } from "../modules/assessments/apiService/milestone-findings-api-service";
import { wafrFindingsFiltersApi } from "../modules/assessments/apiService/findings-filters-api-service";
import { healthEventDetailsApi } from "../modules/alerts/awsHealthDashboard/services/eventDetails";
import { tenantAccountsApi } from "../modules/management-fbp/services/tenant-accounts-api-service";
import { healthEventConfigurationApi } from "../modules/management-fbp/services/health-event-configuration";
import { costTagKeysApi } from "../modules/cost/components/dashboard/ResourceExplorer/ResourceGroupByTag/apiService/cost-tag-keys-api-service";
import { costTagValuesApi } from "../modules/cost/components/dashboard/ResourceExplorer/ResourceGroupByTag/apiService/cost-tag-values-api-service";
import { assessmentListApi } from "../modules/assessments/apiService/assessment-list-api-service";
import { azureDashboardAPI } from "../modules/dashboard/components/azure-dashboard/apiService/azure-dashboard-api-service";
import { customLineItemsAPI } from "../modules/cost/abc/components/credits/services/customLineItems";
import { creditsAPI } from "../modules/cost/abc/components/credits/services/creditsApiServices";
import credits from "../modules/cost/abc/reducers/creditsReducers";
import { federatedAccessApi } from "../modules/FederatedAccess/service/federated-access-api";
import { marketplaceChargesAPI } from "../modules/cost/abc/components/marketplace-charges/services/marketplaceChargesAPIServices";
import { managementReportsAPI } from "../modules/management-reports/services/managementReportsAPIServices";
import { mapHistoricalData } from "../modules/map-project/services/mapHistoricalData";
import { logoAPI } from "../modules/organizations-multi-tenancy/apiService/logo-api-service";
import { assessmentInsightsAPI } from "../modules/assessments/components/assessment-insights/apiService/assessment-insights-apiService";
import { supportChargesAPI } from "../modules/cost/abc/components/support-charges/services/supportChargesAPIServices";

/* 
  ************ IMPORTANT ************
  Arranging keys in alphabetical order makes it 
  easier to find them quickly in Redux-DevTools
*/

const appReducer = combineReducers({
  accounts,
  addAccount,
  adminTask,
  adminTasks,
  accountReducer,
  activateOnprem,
  alerts,
  allResourcesReducer,
  allReports,
  allUsers,
  application,
  applicationTaskReducer,
  appMonitoring,
  appParameters,
  appStorage,
  assessments: assessmentsReducer,
  assessmentChecksSummary: assessmentChecksSummaryReducer,
  assessmentDetails: assessmentDetailsReducer,
  assessmentMilestones: assessmentMilestonesReducer,
  assessmentReview: assessmentReviewReducer,
  attachPolicy,
  auth,
  billingGroupsList,
  billingGroupAccountsList,
  blueprint,
  blueprints,
  blueprintParameter,
  blueprintSharedCatalog,
  botInsight,
  classification,
  computeInstance,
  costPolicies,
  costPolicy,
  commonReducer,
  commons,
  configureCloudWatch,
  createUser,
  createBillingGroup,
  credits,
  dashboard,
  dasParameters,
  dateRangeFilter,
  day2Bots,
  department,
  desiredState,
  desiredStatePolicies,
  desiredStatePolicy,
  environment,
  feedback,
  firstRun,
  foundationalBestPractices: foundationalBestPracticesReducer,
  globalConfig,
  imagesServerOperation,
  inventory,
  instances,
  integrationReducer,
  inventoryPolicies,
  launchConfiguration,
  layout,
  navigation,
  newFirstRun,
  notification,
  mapProject: mapProjectReviewReducer,
  mapProjectDetails,
  mapProjectsList: mapProjects,
  mfaReducer,
  marvinBotState: marvinReducer,
  monitoring,
  notebookInstanceReducer,
  organization,
  organizationManager,
  optimizationReducer,
  payment,
  patchDeployment,
  patchPolicies,
  patchPolicy,
  products,
  regions,
  reportCriteria,
  reportFilter,
  reports,
  reportSchedule,
  resource,
  resourceOptimization,
  roles,
  rules,
  s3,
  securityPolicies,
  serverGroup,
  serverGroups,
  signup,
  snapshots,
  sso,
  subscription,
  supportTicket,
  toggle,
  tokenReducer,
  tour,
  task,
  tasks,
  taskDetails,
  taskParameter,
  taskOutput,
  userData,
  users,
  volumes,
  vulnerabilityScanning,
  workloadDetails: detailsReducers,
  workspaceReducer,
  awsAccounts,
  costChart: costChartReducer,
  [tenantCategoryAPI.reducerPath]: tenantCategoryAPI.reducer,
  [findingSummaryApi.reducerPath]: findingSummaryApi.reducer,
  [milestoneFindingSummaryApi.reducerPath]: milestoneFindingSummaryApi.reducer,
  [wafrFindingsFiltersApi.reducerPath]: wafrFindingsFiltersApi.reducer,
  [healthEventDetailsApi.reducerPath]: healthEventDetailsApi.reducer,
  [tenantAccountsApi.reducerPath]: tenantAccountsApi.reducer,
  [healthEventConfigurationApi.reducerPath]:
    healthEventConfigurationApi.reducer,
  [costTagKeysApi.reducerPath]: costTagKeysApi.reducer,
  [costTagValuesApi.reducerPath]: costTagValuesApi.reducer,
  [assessmentListApi.reducerPath]: assessmentListApi.reducer,
  [azureDashboardAPI.reducerPath]: azureDashboardAPI.reducer,
  [customLineItemsAPI.reducerPath]: customLineItemsAPI.reducer,
  [creditsAPI.reducerPath]: creditsAPI.reducer,
  [federatedAccessApi.reducerPath]: federatedAccessApi.reducer,
  [marketplaceChargesAPI.reducerPath]: marketplaceChargesAPI.reducer,
  [supportChargesAPI.reducerPath]: supportChargesAPI.reducer,
  [managementReportsAPI.reducerPath]: managementReportsAPI.reducer,
  [mapHistoricalData.reducerPath]: mapHistoricalData.reducer,
  [logoAPI.reducerPath]: logoAPI.reducer,
  [assessmentInsightsAPI.reducerPath]: assessmentInsightsAPI.reducer,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT_REQUEST") {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
